@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap";
* {
  margin: 0;
  padding: 0;
}

@media (max-width: 499px) {
  body {
    font-size: 28px !important;
  }

  .profile-nav {
    font-size: .9em;
  }
}

@media (max-width: 899px) and (min-width: 500px) {
  body {
    font-size: 38px !important;
  }
}

@media (max-width: 1499px) and (min-width: 900px) {
  body {
    font-size: 44px !important;
  }
}

@media (max-width: 1999px) and (min-width: 1500px) {
  body {
    font-size: 50px !important;
  }
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  background-color: #f5f3e4;
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 62px;
}

main {
  height: 100%;
  padding: 5%;
}

.profile {
  flex-flow: column;
  gap: 1.5em;
  margin-top: 2em;
  display: flex;
}

h1 {
  font-size: 2em;
  font-weight: 400;
}

.profile h1 {
  overflow-wrap: break-word;
}

.profile-links {
  list-style: none;
}

.blue {
  color: #00f;
}

.profile-nav {
  flex-flow: wrap;
  align-self: flex-end;
  gap: 0 .25em;
  font-size: .8em;
  display: flex;
}

.not-found {
  margin-top: 2em;
}

.no-select {
  -webkit-user-select: none;
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, .active {
  color: #00f;
}

/*# sourceMappingURL=index.c2d231d8.css.map */
