@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* { 
  margin: 0;
  padding: 0;
}

@media (max-width: 499px) {
  body {
    font-size: 28px !important;
  }

  .profile-nav {
    font-size: 0.9em;
  }
}

@media (max-width: 899px) and (min-width: 500px) {
  body {
    font-size: 38px !important;
  }
}

@media (max-width: 1499px) and (min-width: 900px) {
  body {
    font-size: 44px !important;
  }
}

@media (max-width: 1999px) and (min-width: 1500px) {
  body {
    font-size: 50px !important;
  }
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 62px;
  background-color: rgb(245, 243, 228);
}

main {
  padding: 5%;
  height: 100%;
}

.profile {
  display: flex;
  flex-flow: column;
  gap: 1.5em;
  margin-top: 2em;
}

h1 {
  font-weight: 400;
  font-size: 2em;
}

.profile h1 {
  overflow-wrap: break-word;
}

.profile-links {
  list-style: none;
}

.blue {
  color: blue;
}

.profile-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 0.25em;
  align-self: flex-end;
  font-size: 0.8em;
}

.not-found {
  margin-top: 2em;
}

.no-select {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover, .active {
  color: blue;
}
